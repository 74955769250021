import { useRef, useState, useMemo } from "react";
import { Col } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

const leekspin = require("./leekspin.mp4");
const patrick = require("./patrick.mp4");
// const ryan1 = require("./ryan1.jpeg");
// const ryan2 = require("./ryan2.jpeg");
// const ryan3 = require("./ryan3.jpeg");
// const ryan4 = require("./ryan4.jpeg");
// const ryan5 = require("./ryan5.jpeg");
// const ryan6 = require("./ryan6.jpeg");
const head = require("./head.png");

export default function App() {
  const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const containerStyle = { ...styles.container };
  if (isMobile) containerStyle.padding = 20;

  const videoRef = useRef();

  const [playing, setPlaying] = useState(false);

  const videoSrc = useMemo(() => {
    let src = leekspin;
    const i = Math.floor(Math.random() * 100);
    if (i < 10) src = patrick;
    return src;
  }, []);

  const videoStyle = { ...styles.video };
  if (!playing) videoStyle.opacity = 0;

  return (
    <div>
      <div style={containerStyle}>
        <div style={styles.h1}>
          Hi, I'm Ryan Yang.
          <br />
          Welcome to my website
        </div>
        <div style={styles.videoWrapper}>
          <video src={videoSrc} ref={videoRef} loop style={videoStyle} playsInline />
          {!playing && (
            <img
              src={head}
              style={styles.head}
              alt="ryan head"
              className="ryan-head"
              onClick={onClickHead}
            />
          )}
        </div>
        {/* <Row>
          <Image src={ryan1} />
          <Image src={ryan2} />
          <Image src={ryan3} />
          <Image src={ryan4} />
          <Image src={ryan5} />
          <Image src={ryan6} />
        </Row> */}
      </div>
    </div>
  );

  function onClickHead() {
    setPlaying(true);
    videoRef.current.play();
  }
}

// function Image(props) {
//   return (
//     <Col md={4}>
//       <img src={props.src} style={styles.image} alt="Ryan" className="image" />
//     </Col>
//   );
// }

const styles = {
  container: {
    padding: 80,
  },
  h1: {
    fontSize: 48,
    fontWeight: 700,
    textAlign: "center",
    marginBottom: 80,
  },
  images: {
    display: "flex",
    flexWrap: "wrap",
  },
  image: {
    width: "100%",
    height: 400,
    borderRadius: 20,
    objectFit: "cover",
    marginBottom: 20,
  },
  video: {
    display: "block",
    margin: "auto",
  },
  head: {
    position: "absolute",
    width: 160,
    marginTop: 20,
  },
  videoWrapper: {
    display: 'flex',
    justifyContent:'center',
  }
};
